<template>
  <div class="organizations-update">
    <Banner>
      <div class="banner-content">
        <MeepIconChat class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.chat") }}</span>
      </div>
    </Banner>
    <PageHeader :has-back="true" title="Actualités du cabinet" @back="goBack" />
    <div
      :class="organizationUpdateClass"
      class="organizations-update page-layout"
    >
      <LoadingCard v-if="isLoading" />

      <md-card v-if="!isLoading" class="meep-form">
        <!-- le formulaire de création -->
        <md-card-content>
          <div class="meep-form-column">
            <!-- Nom -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('title') }">
                <label>{{ $t("organization-news.title") }} *</label>

                <md-input
                  v-model="form.title"
                  v-validate="'required'"
                  name="title"
                  type="text"
                ></md-input>
                <span v-show="errors.has('title')" class="md-error">{{
                    errors.first("title")
                  }}</span>
              </md-field>
            </div>

            <!-- Logo -->
            <div class="meep-input--file">
              <label class="btn" for="files">
                <md-icon>file_upload</md-icon>
                Télécharger photo
              </label>
              <input
                id="files"
                accept="image/*"
                name="inputFile"
                onclick="this.value = null;"
                style="visibility: hidden;"
                type="file"
                @change="onFileAdded"
              />
            </div>

            <img
              v-show="fileToUpload || dataUrl"
              :src="fileToUpload ? imgSrc : dataUrl"
              class="organizations-update-preview-image"
            />

            <div class="meep-textarea">
              <ckeditor
                v-model="form.description"
                :config="editorConfig"
                :editor="editor"
                class="editor-description"
                placeholder="Description"
                tag-name="textarea"
              />
            </div>

            <span v-show="errors.has('description')" class="md-error">{{
                errors.first("description")
              }}</span>

            <!-- Lien -->
            <div class="meep-input">
              <md-field>
                <label>Lien</label>
                <md-input v-model="form.link" name="link" type="url"></md-input>
              </md-field>
            </div>

            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('scope') }">
                <label>Pour*</label>
                <md-select v-model="form.scope">
                  <md-option value="0">Tous</md-option>
                  <md-option value="2">Collaborateur</md-option>
                  <md-option value="4">Client</md-option>
                </md-select>
                <span v-show="errors.has('scope')" class="md-error">{{
                    errors.first("scope")
                  }}</span>
              </md-field>
            </div>
          </div>
          <!-- Date d'envoi -->
          <div class="meep-form-column">
            <md-switch ref="sendLaterSwitch" v-model="form.sendLater">
              {{ $t("meep-form.date-text") }}
            </md-switch>
            <md-datepicker v-if="form.sendLater" v-model="form.date">
              <label>Date d'envoi</label>
            </md-datepicker>

            <md-switch v-if="form.sendLater" v-model="form.sendHourLater"
            >{{ $t("meep-form.hour-text") }}
            </md-switch>
            <div
              v-if="form.sendLater && form.sendHourLater"
              class="meep-input meep-time"
            >
              <label class="meep-label"> Heure d'envoi </label>
              <vue-timepicker v-model="form.hour" format="HH:mm" />
            </div>

            <div class="md-layout md-alignment-center-right">
              <md-button
                class="md-raised md-primary meep-form__submit-btn"
                @click.native="updateNews"
              >
                Sauvegarder
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import organizationsNews from "../../model/organizationsNews.js";
import filesModel from "../../model/files.js";
import Vue from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import { createBlobFromBinary, blobToDataURL } from "@/services/util";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueTimepicker from "vue2-timepicker";
import PageHeader from "@/components/PageHeader";
import LoadingCard from "@/components/LoadingCard";
import { mapGetters } from "vuex";
import "../../styles/cropper.min.css";

import Banner from "@/components/Banner";
import MeepIconChat from "@/components/icons/MeepIconChat.vue";
import CKEditorConfig from "@/mixins/CKEditorConfig.js";

Vue.use(CKEditor);

export default {
  name: "UpdateOrganizationNews",

  components: {
    LoadingCard,
    Banner,
    MeepIconChat,
    "vue-timepicker": VueTimepicker,
    PageHeader,
  },

  mixins: [CKEditorConfig],

  data() {
    return {
      isDebug: false,
      fileToUpload: null,
      imgSrc: "",
      form: {
        title: "",
        description: "",
        link: "",
        scope: 0,
        logo: null,
        sendLater: false,
        sendHourLater: false,
        date: null,
        hour: {
          HH: "00",
          mm: "00",
        },
      },
      dataUrl: "",
      isLoading: false,
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      showTopButton: true,
      language: "fr",
    };
  },

  computed: {
    ...mapGetters(["isJEP", "isJEPA"]),

    organizationUpdateClass() {
      return {
        "organizations-update--jepa": this.isJEPA,
        "organizations-update--jep": this.isJEP,
      };
    },
  },

  mounted() {
    this.getArticle(this.$route.params.id);
  },

  methods: {
    goBack() {
      window.history.back();
    },

    async getArticle(id) {
      this.loader = true;
      try {
        const article = await organizationsNews.get(id);
        this.form = {
          ...article,
          sendLater: !!article.publishAt,
          sendHourLater: !!article.publishAt,
        };
        if (new Date(this.form.publishAt) > new Date()) {
          this.form.date = new Date(this.form.publishAt);

          this.form.hour = {
            HH:
              this.form.date.getHours() === 0
                ? "00"
                : this.form.date.getHours(),
            mm:
              this.form.date.getMinutes() === 0
                ? "00"
                : this.form.date.getMinutes(),
          };
        } else {
          this.form.date = new Date(this.form.created_at);
          this.form.hour = {
            HH: "00",
            mm: "00",
          };
        }

        this.setNewsDataUrl(article);
        this.$nextTick(() => {
          this.loader = false;
        });
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
        this.isLoading = false;
      }
    },

    async setNewsDataUrl(news) {
      if (!news.dataUrl && news.logo) {
        const downloadedFile = await filesModel.downloadImage(news.logo);

        const blob = createBlobFromBinary(downloadedFile.binary, "image/jpeg");

        await new Promise(resolve => {
          blobToDataURL(blob, dataUrl => {
            this.dataUrl = dataUrl;
            Vue.set(news, dataUrl, dataUrl);
            resolve();
          });
        });
      }
    },

    async updateNews() {
      this.isLoading = true;
      const result = await this.$validator.validateAll();
      if (result) {
        if (this.sendLater) {
          if (this.isDateInvalid(this.form.date, this.form.hour)) {
            this.$toasted.global.AppError({
              message:
                "Vous ne pouvez pas choisir une date et une heure antérieur à maintenant",
            });

            return;
          }
        }

        if (this.fileToUpload) {
          filesModel.uploadImage(this.fileToUpload).then(file => {
            this.form.logo = file.fileName;
            organizationsNews
              .create(this.form)
              .then(() => {
                this.$router.push("/dashboard/communicate/organization-news/");

                window.history.back();
                this.isLoading = false;
              })
              .catch(err => {
                this.$toasted.global.AppError({
                  message: err.msg,
                });
                this.isLoading = false;
              });
          });
        } else {
          try {
            await organizationsNews.update(this.form);
            this.$toasted.global.AppSucces({
              message: this.$t("organization-news.update-success"),
            });

            this.$router.push("/dashboard/communicate/organization-news/");
            this.isLoading = false;
          } catch (err) {
            this.$toasted.global.AppError({
              message: err.msg,
            });
            this.isLoading = false;
          }
        }
      } else {
        this.$toasted.global.AppInfo({
          message: "Vous devez remplir les champs manquants",
        });
        this.isLoading = false;
      }
    },

    isDateInvalid(date, hour) {
      const today = new Date();
      if (this.form.sendLater) {
        date.setHours(hour.HH, hour.mm);
      } else {
        today.setHours(0, 0, 0, 0);
      }
      return date < today;
    },

    async onFileAdded(e) {
      if (!e.target.files.length) {
        return;
      }
      this.fileToUpload = e.target.files[0];
      const reader = new FileReader();
      reader.onload = event => {
        this.imgSrc = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload);
      //this.form.logo = logo;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variable.scss";

#app {
  .organizations-update {
    &.organizations-update--jepa {
      .md-card-content {
        .md-field {
          &.md-has-value {
            label {
              font-size: 12px !important;
            }
          }
        }
      }
    }

    &.page-layout {
      height: 100%;
    }

    .logo-preview {
      width: 220px;
      height: 128px;
    }

    .editor-description {
      width: 100%;
    }

    .meep-form {
      &.md-card {
        .md-card-content {
          .md-field {
            &.md-datepicker {
              background: #f2f5f7;
              margin-top: 0px;
              padding-top: 20px;
              border-radius: 9px;

              &:after, &:before {
                display: none;
              }

              input {
                padding: toRem(12) 0 0 0;
                font-size: toRem(13);
                @include for-lg {
                  padding: toRem(15) 0 0 0;
                  font-size: toRem(17);
                }
              }

              label {
                top: 12px;
                left: 12px;
                display: block;
              }
            }
          }

          .meep-input {
            .meep-label {
              margin-bottom: 5px;
            }

            .md-input {
              @include for-lg {
                padding: toRem(15) 0 0 toRem(19);
              }
            }
          }

          .meep-time {
            padding: 0 toRem(12);
            height: 48px;
            display: flex;
            align-items: center;
            @include for-lg {
              height: 50px;
            }

            .time-picker {
              margin-top: toRem(15);

              input {
                font-size: toRem(12);
                @include for-lg {
                  font-size: toRem(17);
                }
              }
            }

            label {
              position: absolute;
              top: 5px;
              font-size: toRem(9);
              @include for-lg {
                font-size: toRem(12);
              }
            }
          }

          .meep-input--file {
            label {
              min-width: unset;
              min-height: unset;
              height: 34px;
              padding: 0 1rem;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }

    &-preview-image {
      display: block;
      margin: 10px auto 20px;
    }
  }
}
</style>
